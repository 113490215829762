<template lang="pug">
figure(class='max-w-[250px] h-[140px] w-full border border-gray-100')
  .flex.items-center.justify-center.w-full.h-full(v-if='isCapturing')
    Icon.w-10.h-10(name='mingcute:loading-3-fill' class='animate-spin')
  img.w-full.h-full(:src='cvImage' v-else-if='cvImage' class='object-cover object-top')

</template>

<script setup lang="ts">
const { cvImage, isCapturing } = useCaptureLogo()
</script>

<style scoped></style>
